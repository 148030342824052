import fetch from 'cross-fetch';
import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client';

const backend = new HttpLink({
  uri: process.env.GATSBY_STRAPI_SRC+'/graphql',
  fetch,
});

const feed = new HttpLink({
  uri: process.env.GATSBY_STRAPI_FEED_SRC+'/graphql',
  fetch,
});

export const client = new ApolloClient({
  link: ApolloLink.split(
    operation => operation.getContext().clientName === "feed",
    // the string "third-party" can be anything you want,
    // we will use it in a bit
    feed, // <= apollo will send to this if clientName is "third-party"
    backend // <= otherwise will send to this
  ),
  cache: new InMemoryCache()
  // other options
});